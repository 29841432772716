import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import _get from 'lodash/get'
import { useEpisode } from '../hooks/episodeHooks'
import {
  fetchEpisodeById,
  fetchSeriesById,
  seriesCleared,
  episodeCleared,
  fetchSeriesSubmissionsById
} from '../actions'
import latestEpisodesFromSeries from '../helpers/latestEpisodesFromSeries'
import { useSeriesId, useContentType } from '../hooks/seriesHooks'
import { useLatestEpisodes } from '../hooks/seasonHooks'
import logger from '../../../logger'
import FourOhFour from '../../404'

const PageDataBySeriesIdAndEpisodeId = ({ children }) => {
  const [errorStatus, setErrorStatus] = useState(null)
  const location = useLocation()
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()
  const seriesId = useSeriesId()
  const episode = useEpisode()
  const latestEpisodes = useLatestEpisodes()
  const contentType = useContentType()

  useEffect(() => {
    Promise.all([
      Promise.resolve().then(() => {
        if (seriesId !== params.seriesId || (!!contentType && contentType !== 'onDemand')) {
          dispatch(seriesCleared())
          const seriesLoadDataPromise = dispatch(fetchSeriesById(params.seriesId)).loadDataPromise.then(
            ({ payload }) => {
              return latestEpisodesFromSeries(payload).episodes
            }
          )

          const seriesSubmissionsLoadDataPromise = dispatch(fetchSeriesSubmissionsById(params.seriesId)).loadDataPromise

          return Promise.allSettled([seriesLoadDataPromise, seriesSubmissionsLoadDataPromise]).then(
            ([seriesFetchResult]) => {
              const { status, value, reason } = seriesFetchResult
              return status === 'fulfilled' ? value : Promise.reject(reason)
            }
          )
        }
        return latestEpisodes
      }),
      Promise.resolve().then(() => {
        if (episode.episodeId !== params.programId || (!!contentType && contentType !== 'onDemand')) {
          dispatch(episodeCleared())
          return dispatch(fetchEpisodeById(params.programId)).loadDataPromise.then(({ payload }) => payload)
        }
        return episode
      })
    ])
      .then(([latestEpisodes, episode]) => {
        const episodeInlatestEpisodes = latestEpisodes.some(
          latestEpisode => latestEpisode.episodeId === episode.episodeId
        )

        if (!episodeInlatestEpisodes) {
          const seasonHref = _get(episode, '_links.season.href', '')
          const [seasonId] = seasonHref.split('/').reverse()

          if (!seasonId) {
            // Might happen if episode is not added to any season in the versioning admin tool
            return null
          }
          const seasonIdUrlPart = `/sesong/${seasonId}`
          const redirectUrl = `/serie/${params.seriesId}${seasonIdUrlPart}/${params.programId}${location.search}${location.hash}`

          logger.info(`Redirecting to ${redirectUrl}`)
          history.replace(redirectUrl)
        }
      })
      .catch(error => {
        setErrorStatus(error.status)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  if (errorStatus) {
    return <FourOhFour />
  }

  return <>{children}</>
}

PageDataBySeriesIdAndEpisodeId.propTypes = { children: PropTypes.node }

export default PageDataBySeriesIdAndEpisodeId
